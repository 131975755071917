import { ApplicationController } from "../application_controller";
import IMask from 'imask';
import AutoNumeric from 'autonumeric';
import Inputmask from "inputmask";

export default class extends ApplicationController {
  static targets = ["phone", "number", "coefficient", "price", "rate", "email",
    "nemail", "int_percent", "two_int", "tin", "three_int", "count", "int_range",
    "latin_text", "hundred_percent", "price_negative"]

  initialize() {
    // latin_text
    if (this.hasLatin_textTarget) {
      const maskOptions = {
        mask: /^[a-zA-Z1-9 ]+$/
      }

      IMask(this.latin_textTarget, maskOptions);
    }

    // phone
    if (this.hasPhoneTarget) {

      const COUNTRIES = JSON.parse(document.body.getAttribute("data-preferred-countries"));

      let phone_input = this.phoneTarget;
      let format_input = window.intlTelInput(phone_input, {
        // allowDropdown: false,
        // autoHideDialCode: false,
        autoPlaceholder: true,
        // dropdownContainer: document.body,
        // excludeCountries: ["us"],
        formatOnDisplay: true,
        // hiddenInput: "full_number",
        initialCountry: "auto",
        localizedCountries: { 'ru': 'Russia' },
        nationalMode: false,
        // onlyCountries: ['us', 'gb', 'ch', 'ca', 'do'],
        // preferredCountries: ["uz"],
        placeholderNumberType: "MOBILE",
        preferredCountries: COUNTRIES,
        separateDialCode: false,
        utilsScript: "/js/utils.js"
      });

      phone_input.addEventListener("keyup", function () {
        if (typeof intlTelInputUtils !== 'undefined') {
          const currentText = format_input.getNumber(intlTelInputUtils.numberFormat.E164);
          if (typeof currentText === 'string') {
            format_input.setNumber(currentText);
          }
        }
      });

      phone_input.addEventListener("change", function () {
        if (typeof intlTelInputUtils !== 'undefined') {
          const currentText = format_input.getNumber(intlTelInputUtils.numberFormat.E164);
          if (typeof currentText === 'string') {
            format_input.setNumber(currentText);
          }
        }
      });

      phone_input.addEventListener("focusout", function () {
        if (!this.value) {
          this.value = "+";
        }
      });

    }

    // price
    if (this.hasPriceTarget) {
      const maskOptions = {
        allowDecimalPadding: "floats",
        // decimalPlaces: 2,
        digitGroupSeparator: " ",
        decimalCharacter: ".",
        decimalCharacterAlternative: ",",
        modifyValueOnWheel: false,
        selectNumberOnly: true,
        minimumValue: 0
      }

      // IMask(this.priceTarget, maskOptions);
      this.priceTarget.numeric = new AutoNumeric(this.priceTarget, maskOptions);
    }

    // price_negative
    if (this.hasPrice_negativeTarget) {
      const maskOptions = {
        allowDecimalPadding: "floats",
        // decimalPlaces: 2,
        digitGroupSeparator: " ",
        decimalCharacter: ".",
        decimalCharacterAlternative: ",",
        modifyValueOnWheel: false,
        selectNumberOnly: true,
        minimumValue: "-99999999999999"
        // negativeSignCharacter: "-"
      }

      this.price_negativeTarget.numeric = new AutoNumeric(this.price_negativeTarget, maskOptions);
    }

    // rate
    if (this.hasRateTarget) {
      const maskOptions = {
        allowDecimalPadding: false,
        showWarnings: false,
        decimalPlaces: 6,
        digitGroupSeparator: " ",
        decimalCharacter: ".",
        decimalCharacterAlternative: ",",
        modifyValueOnWheel: false,
        selectNumberOnly: true,
        minimumValue: 0
      }
      this.rateTarget.numeric = new AutoNumeric(this.rateTarget, maskOptions);
    }


    // int_percent
    if (this.hasInt_percentTarget) {

      const maskOptions = {
        // allowDecimalPadding: "false",
        decimalPlaces: 0,
        digitGroupSeparator: " ",
        decimalCharacter: ".",
        decimalCharacterAlternative: ",",
        modifyValueOnWheel: false,
        styleRules: {
          positive: "autoNumeric-positive"
        },
        minimumValue: 0
      }

      // IMask(this.priceTarget, maskOptions);
      this.int_percentTarget.numeric = new AutoNumeric(this.int_percentTarget, maskOptions);
    }

    // two int
    if (this.hasTwo_intTarget) {

      const maskOptions = {
        // allowDecimalPadding: "false",
        decimalPlaces: 0,
        digitGroupSeparator: " ",
        decimalCharacter: ".",
        decimalCharacterAlternative: ",",
        modifyValueOnWheel: false,
        styleRules: {
          positive: "autoNumeric-positive"
        },
        maximumValue: 99
      }

      // IMask(this.priceTarget, maskOptions);
      this.two_intTarget.numeric = new AutoNumeric(this.two_intTarget, maskOptions);
    }

    // three_int
    if (this.hasThree_intTarget) {
      const maskOptions = {
        // allowDecimalPadding: "false",
        decimalPlaces: 0,
        digitGroupSeparator: " ",
        decimalCharacter: ".",
        decimalCharacterAlternative: ",",
        modifyValueOnWheel: false,
        styleRules: {
          positive: "autoNumeric-positive"
        },
        minimumValue: 0
      }

      // IMask(this.priceTarget, maskOptions);
      this.three_intTarget.numeric = new AutoNumeric(this.three_intTarget, maskOptions);
    }

    // int range
    if (this.hasInt_rangeTarget) {
      const maxValue = this.int_rangeTarget.getAttribute("data-max-value") || 999;
      const minValue = this.int_rangeTarget.getAttribute("data-min-value") || 0;
      const maskOptions = {
        // allowDecimalPadding: "false",
        decimalPlaces: 0,
        digitGroupSeparator: " ",
        decimalCharacter: ".",
        decimalCharacterAlternative: ",",
        modifyValueOnWheel: false,
        styleRules: {
          positive: "autoNumeric-positive"
        },
        maximumValue: maxValue,
        minimumValue: minValue
      }

      // IMask(this.priceTarget, maskOptions);
      this.int_rangeTarget.numeric = new AutoNumeric(this.int_rangeTarget, maskOptions);
    }

    // tin
    if (this.hasTinTarget) {

      // const maskOptions = {
      //   allowDecimalPadding: "floats",
      //   decimalPlaces: 0,
      //   digitGroupSeparator: " ",
      //   decimalCharacter: ".",
      //   decimalCharacterAlternative: ",",
      //   styleRules: {
      //     positive: "autoNumeric-positive"
      //   },
      //   maximumValue: 999999999999999999999999999999
      // }

      // // IMask(this.priceTarget, maskOptions);
      // this.tinTarget.numeric = new AutoNumeric(this.tinTarget, maskOptions);

      const maskOptions = {
        mask: /^[1-9]\d{0,30}$/
      }

      IMask(this.tinTarget, maskOptions);
    }
  }

  connect() {
    // coefficient
    if (this.hasCoefficientTarget) {
      const maskOptions = {
        mask: Number,  // enable number mask
        // other options are optional with defaults below
        scale: 0,  // digits after point, 0 for integers
        signed: false,  // disallow negative
        thousandsSeparator: ' ',  // any single char
        padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
        normalizeZeros: true,  // appends or removes zeros at ends
        radix: ',',  // fractional delimiter
        mapToRadix: ['.'],  // symbols to process as radix

        // additional number interval options (e.g.)
        min: 0,
        max: 99
      }

      IMask(this.coefficientTarget, maskOptions);
    }

    // hundred_percent
    if (this.hasHundred_percentTarget) {
      const maskOptions = {
        mask: Number,  // enable number mask
        // other options are optional with defaults below
        scale: 0,  // digits after point, 0 for integers
        signed: false,  // disallow negative
        thousandsSeparator: ' ',  // any single char
        padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
        normalizeZeros: true,  // appends or removes zeros at ends
        radix: ',',  // fractional delimiter
        mapToRadix: ['.'],  // symbols to process as radix

        // additional number interval options (e.g.)
        min: 0,
        max: 100
      }

      IMask(this.hundred_percentTarget, maskOptions);
    }

    // number
    if (this.hasNumberTarget) {
      const maskOptions = {
        mask: Number,  // enable number mask
        // other options are optional with defaults below
        scale: 0,  // digits after point, 0 for integers
        signed: false,  // disallow negative
        thousandsSeparator: ' ',  // any single char
        padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
        normalizeZeros: true,  // appends or removes zeros at ends
        radix: ',',  // fractional delimiter
        mapToRadix: ['.'],  // symbols to process as radix

        // additional number interval options (e.g.)
        min: 0,
        max: 99
      }

      IMask(this.numberTarget, maskOptions);
    }

    // count
    if (this.hasCountTarget) {
      const maskOptions = {
        mask: Number,  // enable number mask
        // other options are optional with defaults below
        scale: 0,  // digits after point, 0 for integers
        signed: false,  // disallow negative
        thousandsSeparator: ' ',  // any single char
        padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
        normalizeZeros: true,  // appends or removes zeros at ends
        radix: ',',  // fractional delimiter
        mapToRadix: ['.']  // symbols to process as radix
      }

      IMask(this.countTarget, maskOptions);
    }

  }
}
